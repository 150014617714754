// export default {
// 	URL: 'http://localhost:3007/',
// 	URL_IMG: 'http://localhost:3007/',
// 	URL_UPLOAD: 'http://localhost:3007/upload-image/',
// 	URL_DELETE: 'http://localhost:3007/delete-image/',
// }

export default {
	URL: 'https://rvscs-develop.com/guitar-service/',
	URL_IMG: 'https://rvscs-develop.com/guitar-service/',
	URL_UPLOAD: 'https://rvscs-develop.com/guitar-service/upload-image/',
	URL_DELETE: 'https://rvscs-develop.com/guitar-service/delete-image/',
   }