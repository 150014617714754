import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import '../../assets/css/body.css'
import GOBALS from '../../GOBALS';
import Banner from '../Banner'
import { Link } from "react-router-dom";

import PromotionDetailModel from '../../models/PromotionDetailModel';

const promotion_detail_model = new PromotionDetailModel();
export default class detail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            promotion_detail: []
        };
    }

    componentDidMount() {
        this._fetchData()
    }

    async _fetchData() {
        const promotion_code = this.props.match.params.promotion_code;
        const promotions_detail = await promotion_detail_model.getPromotionDetailByCode({
            promotion_detail_language: sessionStorage.getItem('language'),
            promotion_code: promotion_code
        });
        this.setState({
            promotion_detail: promotions_detail.data[0]
        })
    }

    render() {
        return (
            <div>
                <div style={{ marginTop: '42px' }}>
                    <Banner
                        page="How-To-Buy"
                    />
                </div>
                <div className="white-navigation">
                    <Link to="/home">
                        <div>Home</div>
                    </Link>
                    <div>/</div>
                    <Link to="/how-to-buy">
                        <div>How To Buy</div>
                    </Link>
                    <div>/</div>
                    <div>How To Buy Detail</div>
                </div>
                <Container>
                    <Row>
                        <Col className="title">
                            {this.state.promotion_detail.promotion_detail_name}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '1.5rem' }}>
                        <Col>
                            {this.state.promotion_detail.promotion_image === '' || this.state.promotion_detail.promotion_image === undefined ?

                                "" :
                                <img
                                    src={`${GOBALS.URL_IMG}${this.state.promotion_detail.promotion_image}`}
                                    style={{ height: "auto", width: "100%" }}
                                />
                            }
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '2rem', marginBottom: '4rem' }}>
                        <Col style={{ fontSize: '1.2rem' }}>
                            <div dangerouslySetInnerHTML={{ __html: this.state.promotion_detail.promotion_detail_description }} />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
