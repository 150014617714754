import React, { Component, useEffect } from 'react'
import '../assets/css/navbar-header.css'
import '../assets/css/footer.css'
import { Container, Row, Col } from 'react-bootstrap';
import GOBALS from '../GOBALS';
import GoogleMapReact from 'google-map-react';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
    Link
} from 'react-router-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import Policy from './policy'
import Home from './Home/view'
import AboutUs from './AboutUs/view'
import PromotionView from './Promotion/view'
import PromotionDetail from './Promotion/detail'
import AccessoriesType from './AccessoriesType/view'
import AccessoriesView from './Accessories/view'
import AccessoriesDetail from './Accessories/detail'
import GuitarType from './GuitarType/view'
import GuitarView from './Guitar/view'
import GuitarDetail from './Guitar/detail'
import BrandStory from './BrandStory/view'
import AlbumView from './Album/view'
import AlbumDetail from './Album/detail'
import Contact from './Contact/view'
import PopularGuitar from './Guitar/popular'
import PopularAccessories from './Accessories/popular'
import SendMessageLineSuccess from './SendMessageLineSuccess'
import Catalog from './Catalog/view'


import SettingModel from '../models/SettingModel';
import LanguageModel from '../models/LanguageModel';

const setting_model = new SettingModel();
const language_model = new LanguageModel();


export default class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            setting: [],
            languages: [],
            toggle: false,
            active_menu: ''
        };
    }

    async componentWillReceiveProps(nextProps) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.setState({
                active_menu: nextProps.location.pathname
            })
        }
    }

    componentDidMount() {
        this._fetchData()
    }

    

    async _fetchData() {
        const languages = await language_model.getLanguageBy();
        if (sessionStorage.getItem('language') == null) {
            let language_default = languages.data.result.find(language => language.language_default === 'Yes');
            sessionStorage.setItem('language', language_default.language_type)
        }
        const setting = await setting_model.getSettingBy({ address_language: sessionStorage.getItem('language') });
        this.setState({
            setting: setting.data[0],
            languages: languages.data.result,
        })
    }

    _changeLanguage(language) {
        sessionStorage.setItem('language', language)
        window.location.reload();
    }

    _handleApiLoaded = (map, maps) => {
        var myLatLng = {
            lat: parseFloat(this.state.setting.setting_lat),
            lng: parseFloat(this.state.setting.setting_lng)
        };
        new maps.Marker({
            position: myLatLng,
            map,
            title: this.state.setting.address_detail
        });
    }
    static defaultProps = {
        zoom: 16
    };

    _closeMenu() {
        this.setState({
            toggle: false
        })
    }
    render() {
        const { languages } = this.state
        const count_language = languages.length
        return (
            <div>
                <div>
                    <div
                        className={"menu-toggle-header" + (this.state.toggle ? ' active' : '')}
                        onClick={() => {
                            if (this.state.toggle) {
                                this.setState({ toggle: false })
                            } else {
                                this.setState({ toggle: true })
                            }
                        }}
                    >
                        <i
                            className={this.state.toggle ? 'fa fa-times' : 'fa fa-bars'}
                        >
                        </i>
                    </div>

                    {/* <----NavBar Top---->*/}
                    <div className="topnav">
                        <Link to="/home">
                            <img src={require('../assets/img/logo-header.png')} className="logo-header" alt="logo-header" />
                        </Link>
                        <div className="topnav-menu-right">
                            {languages.map((item, no) => (
                                <a key={no}>
                                    <img
                                        onClick={() => this._changeLanguage(item.language_type)}
                                        src={GOBALS.URL_IMG + item.language_image}
                                        className="icon-img-header"
                                        alt={`logo-header${no}`}
                                    />
                                    {count_language !== no + 1 ?
                                        <i className="icon-1-header">/</i>
                                        : ''
                                    }
                                </a>
                            ))}
                        </div>
                    </div>

                    {/* <-----NavBar Left----> */}
                    <nav id="sidebar-wrapper-left-header" className={this.state.toggle ? 'active' : ''} onClick={() => this._closeMenu()}>
                        <ul className="sidebar-nav-left-header">
                            <li className="sidebar-nav-item-left-header">
                                <Link to="/home"
                                    className={this.state.active_menu === '/home' ? 'menu-color' : ''}
                                >
                                    HOME
                                </Link>
                            </li>
                            <li className="sidebar-nav-item-left-header">
                                <Link to="/founder"
                                    className={this.state.active_menu === '/founder' ? 'menu-color' : ''}
                                >
                                    FOUNDER
                                </Link>
                            </li>
                            <li className="sidebar-nav-item-left-header">
                                <Link to="/brand-story"
                                    className={this.state.active_menu === '/brand-story' ? 'menu-color' : ''}
                                >
                                    BRAND STORY
                                </Link>
                            </li>
                            <li className="sidebar-nav-item-left-header">
                                <Link to="/guitar-type"
                                    className={this.state.active_menu === '/guitar-type' ? 'menu-color' : ''}
                                >
                                    GUITAR
                                </Link>
                            </li>
                            <li className="sidebar-nav-item-left-header">
                                <Link to="/accessories-type"
                                    className={this.state.active_menu === '/accessories-type' ? 'menu-color' : ''}
                                >
                                    ACCESSORIES
                                </Link>
                            </li>
                            <li className="sidebar-nav-item-left-header">
                                <Link to="/catalog"
                                    className={this.state.active_menu === '/catalog' ? 'menu-color' : ''}
                                >
                                    CATALOG
                                </Link>
                            </li>
                            <li className="sidebar-nav-item-left-header">
                                <Link to="/gallery"
                                    className={this.state.active_menu === '/gallery' ? 'menu-color' : ''}
                                >
                                    GALLERY
                                </Link>
                            </li>
                            <li className="sidebar-nav-item-left-header">
                                <Link to="/how-to-buy"
                                    className={this.state.active_menu === '/how-to-buy' ? 'menu-color' : ''}
                                >
                                    HOW TO BUY
                                </Link>
                            </li>
                            <li className="sidebar-nav-item-left-header">
                                <Link to="/contact"
                                    className={this.state.active_menu === '/contact' ? 'menu-color' : ''}
                                >
                                    CONTACT
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>

                {/* <----Body----> */}
                <div id="BodyMain" onClick={() => this._closeMenu()}>
                    {sessionStorage.getItem('language') !== null ?
                        <Switch>
                            <Route exact path="/home" component={Home} />
                            <Route exact path="/founder" component={AboutUs} />
                            <Route exact path="/gallery" component={AlbumView} />
                            <Route exact path="/gallery/detail/:album_code" component={AlbumDetail} />
                            <Route exact path="/how-to-buy" component={PromotionView} />
                            <Route exact path="/how-to-buy/detail/:promotion_code" component={PromotionDetail} />
                            <Route exact path="/accessories-type" component={AccessoriesType} />
                            <Route exact path="/accessories-type/:accessories_type_code" component={AccessoriesView} />
                            <Route exact path="/accessories-type/:accessories_type_code/:accessories_code" component={AccessoriesDetail} />

                            <Route exact path="/guitar-type" component={GuitarType} />
                            <Route exact path="/guitar-type/:guitar_type_code" component={GuitarView} />
                            <Route exact path="/guitar-type/:guitar_type_code/:guitar_code" component={GuitarDetail} />

                            <Route exact path="/brand-story" component={BrandStory} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/popular/guitar" component={PopularGuitar} />
                            <Route exact path="/popular/accessories" component={PopularAccessories} />

                            <Route exact path="/send-message-line-success" component={SendMessageLineSuccess} />

                            <Route exact path="/catalog" component={Catalog} />

                            <Route exact path="/policy" component={Policy} />

                            <Redirect from="/" to='/' />
                        </Switch>
                        : ''
                    }
                </div>
                <MessengerCustomerChat
                    id="MessengerCustomerChat"
                    version="8.0"
                    pageId="101215031662988"
                    appId="329182885045463"
                    language="th_TH"
                />
                {/* <----Footer----> */}
                <div className="footer">
                    <Container fluid>
                        <Row style={{ marginLeft: '20px', marginRight: '20px' }}>
                            <Col md="12">
                                <img src={require('../assets/img/logo-header.png')} alt="logo-header2" className="logo-footer" />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-flex-contact" md="3">
                                <div style={{ fontSize: '1.1rem' }}>
                                    <h5 style={{ fontWeight: 'bold' }}>วันและเวลาทำการ</h5>
                                    <div style={{ marginLeft: '10px', marginTop: '10px' }} dangerouslySetInnerHTML={{ __html: this.state.setting.setting_business_hours }} />
                                </div>
                                <h5 style={{ fontWeight: 'bold' }}>Get In Touch</h5>
                                <div style={{ fontSize: '1rem' }}>
                                    <img
                                        src={require('../assets/img/tell-icon.png')}
                                        alt="tell-icon" width="20px"
                                        style={{ marginBottom: "5px", marginRight: "5px" }}
                                    />
                                    <a
                                        href={`tel:${this.state.setting.setting_tel}`}
                                        target="_blank"
                                        style={{ color: '#000', fontSize: '1rem', overflow: 'hidden' }}
                                    >
                                        {this.state.setting.setting_tel}
                                    </a>
                                </div>
                                <div>
                                    <img
                                        src={require('../assets/img/facebook-icon.png')}
                                        alt="facebook-icon" width="20px"
                                        style={{ marginBottom: "5px", marginRight: "5px" }}
                                    />
                                    <a
                                        href={this.state.setting.setting_facebook}
                                        target="_blank"
                                        style={{ color: '#000', fontSize: '1rem', overflow: 'hidden' }}
                                    >
                                        Facebook Fonzo Guitar
                                    </a>
                                </div>
                                <div>
                                    <i className="fa fa-youtube-play" aria-hidden="true" style={{ color: '#878787', marginRight: "3px", fontSize: '1.2rem' }}></i>
                                    <a
                                        href={this.state.setting.setting_youtube}
                                        target="_blank"
                                        style={{ color: '#000', fontSize: '1rem', overflow: 'hidden' }}
                                    >
                                        Youtube Fonzo Guitar
                                    </a>
                                </div>
                                <div style={{ fontSize: '1rem' }}>
                                    <img
                                        src={require('../assets/img/line-icon.png')}
                                        alt="line-icon" width="20px"
                                        style={{ marginBottom: "5px", marginRight: "5px" }}
                                    />
                                    {this.state.setting.setting_line}
                                </div>
                                <div style={{ fontSize: '1rem' }}>
                                    <img
                                        src={require('../assets/img/mail-icon.png')}
                                        alt="mail-icon" width="20px"
                                        style={{ marginBottom: "5px", marginRight: "5px" }}
                                    />
                                    {this.state.setting.setting_email}
                                </div>
                            </Col>
                            <Col className="col-flex" md="4">
                                <h4 style={{ fontWeight: 'bold', float: 'left' }}>Showroom</h4>
                                {this.state.setting.setting_image_showroom !== undefined ?
                                    <img
                                        src={GOBALS.URL_IMG + this.state.setting.setting_image_showroom}
                                        alt="mail-icon" width="100%"
                                        style={{ marginBottom: "5px" }}
                                    />
                                    : ''
                                }
                            </Col>
                            <Col className="col-flex" md="4">
                                <div style={{ fontSize: '1.15rem' }}>{this.state.setting.address_detail}</div>
                                <div style={{ height: '270px', width: '100%' }}>
                                    {this.state.setting.setting_lat !== undefined ?
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: "AIzaSyBPYt_mZGd-2iotzhpiZKw1_GpZ6H9w3vs" }}
                                            defaultCenter={{
                                                lat: parseFloat(this.state.setting.setting_lat),
                                                lng: parseFloat(this.state.setting.setting_lng)
                                            }}
                                            defaultZoom={this.props.zoom}
                                            yesIWantToUseGoogleMapApiInternals
                                            onGoogleApiLoaded={({ map, maps }) => this._handleApiLoaded(map, maps)}
                                        >
                                        </GoogleMapReact>
                                        :
                                        ''
                                    }
                                </div>
                                <div style={{ fontFamily: 'kanit', color: 'gray', fontWeight: 'normal', marginTop: '20px' }}>2020. All right reserved.</div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}
