import React, { Component } from 'react'
import '../../assets/css/index-responsive-style.css';
import '../../assets/css/index-style.css';
import GOBALS from '../../GOBALS';

import BannerModel from '../../models/BannerModel';
import LanguageModel from '../../models/LanguageModel';
import SettingModel from '../../models/SettingModel';

const banner_model = new BannerModel();
const language_model = new LanguageModel();
const setting_model = new SettingModel();
export default class View extends Component {
    constructor(props) {
        super(props)
        this.state = {
            banners: [],
            languages: [],
            toggle: false,
            setting: []
        };
    }

    componentDidMount() {
        this._fetchData()
    }

    async _fetchData() {
        const languages = await language_model.getLanguageBy();
        if (sessionStorage.getItem('language') == null) {
            let language_default = languages.data.result.find(language => language.language_default === 'Yes');
            sessionStorage.setItem('language', language_default.language_type)
        }
        const banners = await banner_model.getBannerBy({ banner_page: 'Index' });
        const setting = await setting_model.getSettingBy({ address_language: sessionStorage.getItem('language') });
        this.setState({
            banners: banners.data.result[0],
            setting: setting.data[0],
            languages: languages.data.result,
        })
    }

    _changeLanguage(language) {
        sessionStorage.setItem('language', language)
    }

    _closeMenu() {
        this.setState({
            toggle: false
        })
    }
    

    render() {
        return (
            <div>
                <header>
                    <div
                        className={"menu-toggle rounded " + (this.state.toggle ? 'active' : '')}
                        onClick={() => {
                            if (this.state.toggle) {
                                this.setState({ toggle: false })
                            } else {
                                this.setState({ toggle: true })
                            }
                        }}
                    >
                        <i className={this.state.toggle ? 'fa fa-times' : 'fa fa-bars'}> </i>
                    </div>
                    <nav id="sidebar-wrapper" className={this.state.toggle ? 'active' : ''} onClick={() => this._closeMenu()}>
                        <ul className="sidebar-nav">
                            <li className="sidebar-brand">
                                <a></a>
                            </li>
                            <li className="sidebar-nav-item">
                                <a href="https://www.fonzoguitar.com/home">HOME</a>
                            </li>
                            <li className="sidebar-nav-item">
                                <a href="https://www.fonzoguitar.com/founder">FOUNDER</a>
                            </li>
                            <li className="sidebar-nav-item">
                                <a href="https://www.fonzoguitar.com/brand-story">BRAND STORY</a>
                            </li>
                            <li className="sidebar-nav-item">
                                <a href="https://www.fonzoguitar.com/guitar-type">GUITAR</a>
                            </li>
                            <li className="sidebar-nav-item">
                                <a href="https://www.fonzoguitar.com/accessories-type">ACCESSORIES</a>
                            </li>
                            <li className="sidebar-nav-item">
                                <a href="https://www.fonzoguitar.com/catalog">CATALOG</a>
                            </li>
                            <li className="sidebar-nav-item">
                                <a href="https://www.fonzoguitar.com/gallery">GALLERY</a>
                            </li>
                            <li className="sidebar-nav-item">
                                <a href="https://www.fonzoguitar.com/how-to-buy">HOW TO BUY</a>
                            </li>
                            <li className="sidebar-nav-item">
                                <a href="https://www.fonzoguitar.com/contact">CONTACT</a>
                            </li>
                        </ul>
                    </nav>
                </header>
                <div onClick={() => this._closeMenu()}>
                    {this.state.banners.banner_type === 'Image' ?
                        <section id="Banner" style={{ backgroundImage: `url(${GOBALS.URL_IMG}${this.state.banners.banner_url})` }}>
                            <div className="banner-bg-color container-1">
                                <div align="center"><img src={require('../../assets/img/94108.png')} className="logo-1" /></div>
                                <img src={require('../../assets/img/logo2.png')} className="logo-2" />
                                <div className="text-center">Classic and Acoustic Guitar</div>
                                <div className="flag-container">
                                    <div className="flag-card">
                                        {this.state.languages[0] !== undefined ?
                                            <a href="https://www.fonzoguitar.com/home">
                                                <img
                                                    src={GOBALS.URL_IMG + this.state.languages[0].language_image}
                                                    className="icon-th"
                                                    onClick={() => this._changeLanguage(this.state.languages[0].language_type)}
                                                />
                                            </a>
                                            : ''
                                        }
                                        <div className="text-flag">TH</div>
                                    </div>
                                    <div className="flag-card">
                                        {this.state.languages[1] !== undefined ?
                                            <a href="https://www.fonzoguitar.com/home">
                                                <img
                                                    src={GOBALS.URL_IMG + this.state.languages[1].language_image}
                                                    className="icon-eng"
                                                    onClick={() => this._changeLanguage(this.state.languages[1].language_type)}
                                                />
                                            </a>
                                            : ''
                                        }
                                        <div className="text-flag">ENG</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        <div>
                            {this.state.banners.banner_url !== undefined ?
                                <video
                                    autoPlay
                                    muted
                                    loop
                                    id="myVideo"
                                    src={`${GOBALS.URL_IMG}${this.state.banners.banner_url}`}
                                />
                                : ''
                            }
                            {this.state.setting.setting_video_intro_page_mobile !== undefined ?
                                <video
                                    autoPlay
                                    muted
                                    loop
                                    id="myVideoMobile"
                                    src={`${GOBALS.URL_IMG}${this.state.setting.setting_video_intro_page_mobile}`}
                                />
                                : ''
                            }
                            <div className="container-2">
                                <div align="center"><img src={require('../../assets/img/94108.png')} className="logo-1" /></div>
                                <img src={require('../../assets/img/logo2.png')} className="logo-2" />
                                <div className="text-center">Classic and Acoustic Guitar</div>
                                <div className="flag-container">
                                    <div className="flag-card">
                                        {this.state.languages[0] !== undefined ?
                                            <a href="https://www.fonzoguitar.com/home">
                                                <img
                                                    src={GOBALS.URL_IMG + this.state.languages[0].language_image}
                                                    className="icon-th"
                                                    onClick={() => this._changeLanguage(this.state.languages[0].language_type)}
                                                />
                                            </a>
                                            : ''
                                        }
                                        <div className="text-flag">TH</div>
                                    </div>
                                    <div className="flag-card">
                                        {this.state.languages[1] !== undefined ?
                                            <a href="https://www.fonzoguitar.com/home">
                                                <img
                                                    src={GOBALS.URL_IMG + this.state.languages[1].language_image}
                                                    className="icon-eng"
                                                    onClick={() => this._changeLanguage(this.state.languages[1].language_type)}
                                                />
                                            </a>
                                            : ''
                                        }
                                        <div className="text-flag">ENG</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
